import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { graphql, StaticQuery } from "gatsby";

import { Container } from "../styles/elements";
import { PostCard } from "./PostCard";

const StyledPostWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 80px;
`;

const StyledPostHeader = styled.h2`
  font-family: "Tiempos";
  font-size: 26px;
  border-bottom: 1px solid #eee;
  padding: 15px 0;
  margin-bottom: 20px;
`;

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <StyledPostWrapper>
        <StyledPostHeader>Latest Posts</StyledPostHeader>
        {posts &&
          posts.map(({ node: post }, index) => {
            return (
              <PostCard
                key={post.id}
                headline={post.frontmatter.title}
                isFeatured={index === 0 ? true : false}
                excerpt={post.frontmatter.description}
                linkTo={post.fields.slug}
                image={post.frontmatter.headerImg.publicURL}
                date={post.frontmatter.date}
              />
            );
          })}
      </StyledPostWrapper>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              id
              headings {
                value
              }
              fields {
                slug
              }
              frontmatter {
                title
                description
                templateKey
                tags
                date(formatString: "MMMM DD, YYYY")
                headerImg {
                  publicURL
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);
