import React from "react";
import Layout from "../components/Layout";
import BlogRoll from "../components/BlogRoll";
import SectionHeader from "../components/SectionHeader";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <SectionHeader />
        <BlogRoll />
      </Layout>
    );
  }
}
