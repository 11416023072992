import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";

import { HEADING_COLOR } from "../styles/constants";

const StyledHeadline = styled.h3`
  font-family: "Tiempos";
  color: ${HEADING_COLOR};
  font-weight: 400;
  font-size: 24px;
  line-height: 1.4;
`;

const StyledMetadata = styled.div`
  font-size: 13px;
  margin-bottom: 5px;
  color: #222;
`;

const StyledLink = styled.span`
  display: inline-block;
  font-size: 14px;
  margin-top: auto;
  font-weight: bold;
  color: #222;
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background: #222;
    transition: width 0.3s;
  }
  &:hover::after {
    width: 100%;
  }
`;

const StyledExcerpt = styled.p`
  margin-bottom: 2rem;
  color: #595959;
  font-size: 16px;
  line-height: 1.57;
  margin: 0 0 8px;
`;

const StyledPostCard = styled(Link)`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  @media (min-width: 45rem) {
    flex-direction: ${props => (props.isFeatured ? "column" : "row")};
  }
`;

const PostImage = styled.img`
  height: ${props => (props.isFeatured ? "25rem" : "10rem")};
  background: ${props => `url('${props.imgSrc}')`};
  flex-shrink: 0;
  min-width: 300px;
  margin-right: ${props => (props.isFeatured ? 0 : "20px")};
  margin-bottom: 20px;
  background-size: cover;
  background-position: center center;
`;

export const PostCard = ({
  headline,
  excerpt,
  linkTo,
  isFeatured,
  image,
  date
}) => (
  <StyledPostCard isFeatured={isFeatured} to={linkTo} aria-label={headline}>
    <PostImage isFeatured={isFeatured} imgSrc={image} alt="" />
    <div>
      <StyledHeadline>{headline}</StyledHeadline>
      <StyledMetadata>{date}</StyledMetadata>
      <StyledExcerpt>{excerpt}</StyledExcerpt>
      <StyledLink>Learn More</StyledLink>
    </div>
  </StyledPostCard>
);
