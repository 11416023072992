import React from "react";
import headerbg from "../img/bg.png";
import styled from "@emotion/styled";

import { Container } from "../styles/elements";

const StyledSectionHeader = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 40px;
  background: url(${headerbg}) no-repeat;
  background-position-x: 100%;
  background-position-y: center;
  background-color: black;
`;

const StyledHeadLine = styled.h1`
  font-family: 'Tiempos';
  color: white;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
`;

const StyledSubtitle = styled.p`
  font-family: 'Tiempos';
  font-size: 1.35rem;
  color: #999;
`;

export default function SectionHeader() {
  return (
    <StyledSectionHeader>
      <Container>
        <StyledHeadLine>Building the hive</StyledHeadLine>
        <StyledSubtitle>
          Thoughts, learnings and reflections from the ecobee engineering team.
        </StyledSubtitle>
      </Container>
    </StyledSectionHeader>
  );
}
